import React from 'react'
import PropTypes from 'prop-types'

import jay from '../assets/images/About/jay.jpg'
import scott from '../assets/images/About/scott.jpg'
import abstract from '../assets/images/About/abstract.jpg'
import { Portfolio } from './Portfolio/Portfolio';
import { WebsitePortfolio } from './WebsitePortfolio/WebsitePortfolio';
import { Contact } from './Contact/Contact';
import { Success } from './Success/Success';

import Loader from 'react-loader-spinner'
import { sendForm } from '../services'

const originalState = {
  name: "",
  subject: "S&A Contact Form",
  message: "",
  email: "",
  alert: "",
  success: '',
  nameFilled: true,
  emailFilled: true,
  messageFilled: true,
}

class Main extends React.Component {
  state = originalState;

  handleChange = (field, value) => {
    this.setState({ [field]: value })
  }

  resetFields = () => {
    this.props.onCloseArticle();
    this.setState(originalState);
  }

  validateInputs = async () => {
    this.setState({ loading: true });
    let { name, message, email } = this.state;
    let nameIsValid = true,
      emailIsValid = true,
      messageIsValid = true;
    if (!name) {
      nameIsValid = false;
    }
    if (!email) {
      emailIsValid = false;
    }
    if (!message) {
      messageIsValid = false;
    }
    if (emailIsValid && nameIsValid && messageIsValid) {
      this.sendEmail();
    }
    else {
      this.setState({ loading: false, nameFilled: nameIsValid, emailFilled: emailIsValid, messageFilled: messageIsValid })
    }
  }

  sendEmail = () => {
    let { name, email, message } = this.state;
    let obj = {
      name,
      email,
      site: 'sargentassociates.com.com',
      message
    };
    sendForm(obj).then(() => {
      this.setState({ loading: false, success: 'true' })
    }).catch(error => {
      this.setState({ loading: false, success: 'false' })
    })
  }

  switchComponent = (close) => {
    let { name, nameFilled, email, emailFilled, message, messageFilled, success } = this.state;
    if (success === 'true') {
      return <Success close={close} resetFields={this.resetFields} />
    }
    else {
      return (
        <Contact 
          name={name} 
          nameFilled={nameFilled} 
          message={message} 
          messageFilled={messageFilled} 
          email={email} 
          emailFilled={emailFilled} 
          validateInputs={this.validateInputs} 
          resetFields={this.resetFields} 
          handleChange={this.handleChange} 
          close={close} 
          error={success === 'false'}
        />
      )
    }
  }

  render() {

    let close = <div className="close" onClick={this.resetFields}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}>

        <article id="design" className={`${this.props.article === 'design' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Design</h2>
          <Portfolio />
          {close}
        </article>

        <article id="web" className={`${this.props.article === 'web' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Web</h2>
          <WebsitePortfolio />
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">About</h2>
          <span className="image main"><img src={abstract} alt="" /></span>
          <h3 className="major">Sargent & Associates</h3>
          <p> Sargent & Associates is a full service design firm that has created graphic and marketing solutions in many forms for businesses such as: 1-800 Contacts, SynergyWorldwide, Eclipse Marketing and many others, internationally and domestically.</p>
          <span className="image main"><img src={scott} alt="" /></span>
          <h3 className="major">Scott Sargent</h3>
          <p> With a design degree from Brigham Young University, Mr. Sargent launched Sargent & Associates in 1990. Mr. Sargent and his design firm have been responsible for the design and integration of graphics in many environments including startup companies, advertising campaigns and promotional strategies. </p>
          <span className="image main"><img src={jay} alt="" /></span>
          <h3 className="major">Jay Sargent</h3>
          <p> Jay Sargent is an experienced full stack software engineer with a flare for design. Jay is currently a developer for a multimillion dollar software company and has been with Sargent & Associates since 2009.</p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          {!this.state.loading ?
            this.switchComponent(close)
            :  <Loader
            type="ThreeDots"
            color="#B99774"
            height="100"
            width="100"
          />
                }
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main