import React, { useState } from 'react'
import { PortfolioItem } from './PortfolioItem';
import { Filter } from '../Filter/Filter';
import { _id, _packaging, _print, _signage } from '../../assets/data/design_items'
import { returnImageArray } from '../../assets/data/fns'


const categories = ["Identity", "Print", "Signage", "Packaging"]

export const Portfolio = props => {
    let [activeCategory, setActiveCategory] = useState("identity");
    let id = returnImageArray(_id, "identity");
    let packaging = returnImageArray(_packaging, "packaging");
    let print = returnImageArray(_print, "print");
    let signage = returnImageArray(_signage, "signage");

    let allArray = [...id, ...packaging, ...print, ...signage];

    let toBeMapped = allArray.filter(imageArray => {
        return activeCategory.toLowerCase().includes(imageArray.category);
    })

    let displayedItems = toBeMapped.map((imageArray, i) => {
        if (i % 2 === 0) {
            return (
                <PortfolioItem key={imageArray.url} img={imageArray.url} side="left" />
            )

        }
        else {
            return (
                <PortfolioItem key={imageArray.url} img={imageArray.url} side="right" />
            )
        }
    })


    return (
        <React.Fragment>
            <Filter categories={categories} setActiveCategory={setActiveCategory} />
            <br />
            {displayedItems}
        </React.Fragment>
    )
}

