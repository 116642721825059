import React from 'react'
import PropTypes from 'prop-types'
import logo from '../assets/logo/transparent_logo.png'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        
        <img className="sa-logo" src={logo} alt="logo" />
        <div className="content">
            <div className="inner">
                <h1> Web & Design </h1>
                <p> Professional Presence that meets Corporate Vision </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('web')}}>Web</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('design')}}>Design</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>About</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
