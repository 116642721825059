import React from 'react'
export const FilterButton = props => {
    let { text, active, fn, flippedProps } = props;
    let activeString = 'active';
    if (!active) {
        activeString = 'inactive';
    }
    return (
            <div className={`filter-button-wrapper ${activeString}`} onClick={() => fn()}>
                {text}
            </div>
    )
}