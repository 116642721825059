import React, { useState } from 'react'
import { FilterButton } from './FilterButton';
import './_filter.scss'

const determineActive = (word, props) => {
    let { getActiveState } = props;
    if (word === 'active') {
        if (getActiveState) getActiveState('inactive');
        return 'inactive';
    }
    else {
        if (getActiveState) getActiveState('active');
        return 'active';
    }
}

const createMenuObjectArray = categories => {
    let menuObjectArray = [];
    let num = -1;
    categories.forEach(item => {
        let obj = {};
        obj.id = ++num;
        obj.text = item;
        if (item === "Identity") {
            obj.active = true;
        }
        else {
            obj.active = false;
        }
        menuObjectArray.push(obj);
    })
    return menuObjectArray;
}

export const Filter = (props) => {
    let { categories } = props;

    let [menuObjects, setMenuObject] = useState(() => {
        const initialObject = createMenuObjectArray(categories);
        return initialObject;
    });

    function onClick(id) {
        let { setActiveCategory } = props;
        let activeCategory;
        let newMenuObjects = menuObjects.map(item => {
            if (item.id === id) {
                activeCategory = item.text;
                return {
                    ...item,
                    active: true
                }
            }
            else {
                return {
                    ...item,
                    active: false
                }
            }
        });
        setMenuObject(newMenuObjects);
        setActiveCategory(activeCategory);
    }

    return (
            <div className="filter-wrapper">
                {menuObjects.map((item, i) => {
                    return (
                            <FilterButton key={i} text={item.text} active={item.active} fn={() => onClick(item.id)} />
                    )
                })}
            </div>
    )
}